import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppStateService} from '../../core/modules/app-state';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private readonly appStateService: AppStateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.appStateService.token;
    const url = request.url;
    if (token && url.includes('api/')) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }
    return next.handle(request);
  }
}
