import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {LoaderService} from '@modules/custom-loader';

import {PlatformBrowserService, UniversalStorageService} from '@core/modules';
import {WINDOW} from '@core/tokens';

import config from '../../../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class VersionGuard  {

  private readonly versionKey = '_version';

  constructor(
    @Inject(UniversalStorageService) private readonly universalStorageService: UniversalStorageService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly platformBrowserService: PlatformBrowserService,
    private readonly loaderService: LoaderService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    if (!this.platformBrowserService.isBrowser) {
      return true;
    }
    const lastVersion = this.universalStorageService.getItem(this.versionKey);
    if (!lastVersion) {
      return this.forceUpdateVersion();
    }
    if (lastVersion === config.version) {
      return true;
    }
    return this.forceUpdateVersion();
  }

  private forceUpdateVersion(): Promise<boolean> | boolean {
    const {caches, navigator, location} = this.window;
    if (!caches || !navigator.serviceWorker) {
      return true;
    }
    this.loaderService.show();
    this.universalStorageService.setItem(this.versionKey, config.version);
    return Promise.all([
      this.clearCaches(caches),
      this.unregisterServiceWorker(navigator.serviceWorker),
    ]).then(([cache, isUnregister]) => {
      const needReload = !!(Array.isArray(cache) && cache.length) || isUnregister;
      if (needReload) {
        const href = location.href;
        // TODO: fix cyclic reload page, don't remove timeout
        setTimeout(() => location.replace(href));
      }
      this.loaderService.hide();
      return !needReload;
    });
  }

  private clearCaches(cacheStorage: CacheStorage): Promise<void | boolean[]> {
    return cacheStorage.keys()
      .then((keyList) => Promise.all(keyList.map((key) => cacheStorage.delete(key))));
  }

  private unregisterServiceWorker(serviceWorker: ServiceWorkerContainer): Promise<boolean> {
    return serviceWorker.getRegistrations().then((registrations) => {
      const length = registrations.length;
      for (const registration of registrations) {
        registration.unregister();
      }
      return !!length;
    });
  }
}
