import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {
  BoatFleetPermissionsService,
} from '@features/boats-fleet/shared/modules/boat-fleet-permissions/services/boat-fleet-permissions.service';
import {AuthService} from '@services/auth/auth.service';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

import {UserDownloadNotificationService} from '../modules/user-download-notification';

@Injectable()
export class CustomHeaderUpdateInterceptor implements HttpInterceptor {

  constructor(
    private readonly boatDetailsService: BoatDetailsService,
    private readonly appStateService: AppStateService,
    private readonly authService: AuthService,
    private readonly userDownloadNotificationService: UserDownloadNotificationService,
    private readonly boatFleetPermissionsService: BoatFleetPermissionsService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse) {
          this.checkBoatUpdate(response);
          this.checkUserUpdate(response);
          this.checkUserDownloadsUpdate(response);
          this.checkUserFleetsUpdate(response);
        }
      }),
    );
  }

  private checkBoatUpdate(response: HttpResponse<any>): void {
    const lastDateUpdate = response.headers.get('X-Boat-Date-Update');
    const boatDateUpdate = this.boatDetailsService.boatDateUpdate;
    if (!boatDateUpdate || !lastDateUpdate || +lastDateUpdate === boatDateUpdate) {
      return;
    }
    this.boatDetailsService.onUpdateBoatShortInfo();
  }

  private checkUserUpdate(response: HttpResponse<any>): void {
    const lastDateUpdate = response.headers.get('X-User-Date-Update');
    const userDateUpdate = this.appStateService.userDateUpdate;
    if (!userDateUpdate || !lastDateUpdate || +lastDateUpdate === userDateUpdate) {
      return;
    }
    this.authService.updateUser();
  }

  private checkUserDownloadsUpdate(response: HttpResponse<any>): void {
    const userDownloadDateUpdate = this.appStateService.userDownloadDateUpdate;
    const lastDateUpdate = response.headers.get('X-Downloads-Date-Update');
    if (!lastDateUpdate) {
      return;
    }
    if (!userDownloadDateUpdate) {
      this.appStateService.userDownloadDateUpdate = +lastDateUpdate;
      return;
    }
    if (+lastDateUpdate === userDownloadDateUpdate) {
      return;
    }
    this.appStateService.userDownloadDateUpdate = +lastDateUpdate;
    this.userDownloadNotificationService.updateList();
  }

  private checkUserFleetsUpdate(response: HttpResponse<any>): void {
    const userFleetsDateUpdate = this.appStateService.userFleetsDateUpdate;
    const lastDateUpdate = response.headers.get('X-Downloads-Date-Update');
    if (!lastDateUpdate) {
      return;
    }
    const lastDateUpdateTime = Number(lastDateUpdate);
    if (!userFleetsDateUpdate) {
      this.appStateService.userFleetsDateUpdate = lastDateUpdateTime;
      return;
    }
    if (lastDateUpdateTime === userFleetsDateUpdate) {
      return;
    }
    this.appStateService.userFleetsDateUpdate = lastDateUpdateTime;
    this.boatFleetPermissionsService.loadPermissions();
  }
}
