import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiEndpoint = environment.apiEndpoint || '/api';
    const apiVersion = environment.apiVersion;
    const url = request.url;
    if (url.includes('api/')) {
      const resultUrl = `${apiEndpoint}/${apiVersion}/${url.replace('api/', '')}`;
      request = request.clone({
        url: resultUrl,
      });
    }
    return next.handle(request);
  }
}
