import {NgModule, Optional, SkipSelf} from '@angular/core';

import {AppStateModule, LocalStorageModule, PlatformBrowserModule, UniversalStorageModule} from './modules';
import {FeatureToggleModule} from './modules/feature-toggle';
import {SessionStorageModule} from './modules/session-storage';
import {AppRouterModule} from './services/app-router';

@NgModule({
  imports: [
    PlatformBrowserModule.forRoot(),
    LocalStorageModule.forRoot(),
    AppStateModule.forRoot(),
    AppRouterModule.forRoot(),
    UniversalStorageModule.forRoot(),
    FeatureToggleModule.forRoot(),
    SessionStorageModule.forRoot(),
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule module should be imported only in AppModule.');
    }
  }

}
