import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {inject, Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {LoaderService} from '@modules/custom-loader';
import {InfoModalService} from '@modules/info-modal';
import {NotificationService} from '@modules/notification';
import {PermissionsService} from '@modules/permissions';
import {BehaviorSubject, EMPTY, Observable, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';

import {PlatformBrowserService} from '@core/modules/browser/platform-browser.service';
import {AppRouterService} from '@core/services';
import {WINDOW} from '@core/tokens';
import {BoatFleetPermissionsService} from '@features/boats-fleet/shared/modules/boat-fleet-permissions';
import {LogOutConfigDto} from '@models/auth';
import {ShareApaReportRoutes} from '@models/general';
import {BadRequestStatusOptions, ErrorDto, NotFoundStatusOptions, StatusOptions} from '@models/response';
import {AuthService} from '@services/auth/auth.service';
import {BoatDetailsService} from '@services/boat-details/boat-details.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private isRefreshingToken = false;
  private isRefreshingFleetPermissions = false;
  private update$ = new BehaviorSubject<boolean | null>(null);
  private readonly boatDetailsService = inject(BoatDetailsService, {optional: true});

  constructor(
    private readonly appRouterService: AppRouterService,
    private readonly notificationService: NotificationService,
    private readonly browserService: PlatformBrowserService,
    private readonly authService: AuthService,
    private readonly permissionsService: PermissionsService,
    private readonly infoModalService: InfoModalService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly loaderService: LoaderService,
    private readonly boatFleetPermissionsService: BoatFleetPermissionsService,
    private readonly router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const serverErrors = err.error as ErrorDto;
        if (this.browserService.isBrowser && serverErrors instanceof Blob) {
          return this.parseErrorBlob(serverErrors);
        }
        return throwError(() => err);
      }),
      catchError((err: HttpErrorResponse) => {
        const serverErrors = (err.error || err) as any;
        const errors = serverErrors && serverErrors.errors || serverErrors;
        const statusOptions = errors.statusOptions;
        if (err.status === 400) {
          if (statusOptions === BadRequestStatusOptions.FormError) {
            const msg = serverErrors && serverErrors.message;
            this.showError(msg, null, true);
            return throwError(() => serverErrors);
          }
          if (statusOptions) {
            return throwError(() => serverErrors);
          }
        }

        if (err.status === 401) {
          this.authService.logoutUser(false, true, new LogOutConfigDto(serverErrors.message));
          return throwError(() => serverErrors);
        }

        if (err.status === 403) {
          if (statusOptions === StatusOptions.EMAIL_VERIFICATION) {
            serverErrors.showModal = true;
            return throwError(() => serverErrors);
          }
          if (statusOptions === StatusOptions.NEED_CHANGE_PASSWORD) {
            serverErrors.showModal = true;
            this.appRouterService.changePassword();
            this.authService.needChangePassword(true);
            return throwError(() => serverErrors);
          }
          if (statusOptions === StatusOptions.USER_FORCED_LOGOUT) {
            const config = new LogOutConfigDto('errors.forcedLogout', 6000, true);
            this.authService.logoutUser(true, true, config);
            return throwError(() => serverErrors);
          }
          if (statusOptions === StatusOptions.BOAT_PERMISSION) {
            return this.handleBoatPermissionError(request, next);
          }

          if (statusOptions === StatusOptions.BOAT_WITHOUT_SUBSCIPTION) {
            const boatId = errors.payloadData && errors.payloadData.boatId;
            if (boatId) {
              this.appRouterService.navigateToAddBoatSubscription(boatId);
              const msg = serverErrors && serverErrors.message;
              this.showError(msg);
              return throwError(() => serverErrors);
            }
          }

          if (statusOptions === StatusOptions.USER_COMPANY_NOT_VERIFIED) {
            this.appRouterService.navigateToSuccessCompanyRegistration();
            return throwError(() => serverErrors);
          }
          if (statusOptions === StatusOptions.CREW_AGENCY_ACCESS_DENIED) {
            return this.handleFleetPermissionError(request, next);
          }
          if (statusOptions === StatusOptions.USER_DELETED || statusOptions === StatusOptions.USER_BANNED) {
            if (this.authService.isAuthorized) {
              this.authService.clearUserData();
              this.appRouterService.navigateToLogin();
            }
            const msg = serverErrors && serverErrors.message;
            this.showError(msg);
            return throwError(() => serverErrors);
          }

          if (statusOptions === StatusOptions.FLEET_PERMISSION) {
            this.appRouterService.navigateToMyFleets();
            const msg = serverErrors && serverErrors.message;
            this.showError(msg);
            return throwError(() => serverErrors);
          }
          if (statusOptions === StatusOptions.SHARED_APA_REPORT_EXPIRED) {
            const msg = serverErrors && serverErrors.message;
            this.showError(msg);
            const {root} = this.router.routerState.snapshot;
            const token = this.getChildRouteToken(root);
            if (!token) {
              this.appRouterService.navigateToLogin();
            } else {
              this.appRouterService.navigateSharedToApaReport(ShareApaReportRoutes.ApaAuth, token);
            }
            return throwError(() => serverErrors);
          }

          if (statusOptions && Object.values(StatusOptions).some(v => v === statusOptions)) {
            return throwError(() => serverErrors);
          }
        }

        if (err.status === 404) {
          if (statusOptions === NotFoundStatusOptions.INVALID_PROMOCODE) {
            return throwError(() => serverErrors);
          }
          if (statusOptions === NotFoundStatusOptions.STATUS_OPTION_JOB_OFFER_UNPUBLISHED) {
            return throwError(() => err);
          }
          if (serverErrors.message) {
            return this.showError(serverErrors.message).pipe(
              switchMap(() => throwError(() => err)),
            );
          }
        }

        if (err.status === 504) {
          return this.handleTimeoutError(err);
        }

        const message = serverErrors && serverErrors.message;
        this.showError(message);
        return throwError(() => serverErrors);
      }),
    );
  }

  private showError(message: string, timeout: number | null = 3000, preventBackdropClick = false): Observable<any> {
    if (!message) {
      return EMPTY;
    }
    return this.notificationService.error(message, timeout, preventBackdropClick);
  }

  private parseErrorBlob(err: Blob): Observable<any> {
    const reader = new FileReader();
    const stream = new Observable((observer) => {
      reader.onload = () => {
        const message = reader.result ? reader.result as string : null;
        observer.error(message);
        observer.complete();
      };
    });
    reader.readAsText(err);
    return stream;
  }

  private handleBoatPermissionError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.update$.next(true);
      const boatId = this.boatDetailsService?.boatId;
      if (boatId) {
        this.permissionsService.onLoadPermissions([boatId]);
      }
      return this.permissionsService.boatsPermissions$
        .pipe(
          switchMap(() => next.handle(request)
            .pipe(
              catchError(err => {
                this.appRouterService.navigateToBoatsList();
                const msg = err.serverErrors && err.serverErrors.message;
                this.showError(msg);
                return throwError(err);
              }),
            )),
          finalize(() => {
            this.isRefreshingToken = false;
          }),
        );
    } else {
      return this.update$
        .pipe(
          filter(val => !!val),
          take(1),
          switchMap(() => next.handle(request)),
        );
    }
  }

  private handleFleetPermissionError(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshingFleetPermissions) {
      this.isRefreshingFleetPermissions = true;
      this.update$.next(true);
      this.boatFleetPermissionsService.loadPermissions();
      return this.boatFleetPermissionsService.fleetPermissions$
        .pipe(
          switchMap(() => next.handle(request)
            .pipe(
              catchError(err => {
                this.appRouterService.navigateToMyFleets();
                return throwError(() => err);
              }),
            )),
          finalize(() => {
            this.isRefreshingFleetPermissions = false;
          }),
        );
    } else {
      return this.update$
        .pipe(
          filter(val => !!val),
          take(1),
          switchMap(() => next.handle(request)),
        );
    }
  }

  private handleTimeoutError(err: HttpErrorResponse): Observable<any> {
    const isBrowser = this.browserService.isBrowser;
    if (!isBrowser) {
      return throwError(() => err);
    }
    this.loaderService.forceClose();
    const isOnline = this.window.navigator.onLine;
    if (isOnline) {
      return this.infoModalService.openRoundedModal('errors.timeoutText', 'errors.timeout', true);
    }
    return this.infoModalService.openRoundedModal('errors.noInText', 'errors.noIn', true);
  }

  private getChildRouteToken(snapshot: ActivatedRouteSnapshot, url: string | null = null): string | null {
    const {params, firstChild} = snapshot;
    if (!firstChild) {
      return url;
    }
    if (params.token) {
      url = params.token;
      return url;
    }
    return this.getChildRouteToken(firstChild);
  }
}
