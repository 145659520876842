import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OnlyDevGuard} from 'app/shared/guards/only-dev/only-dev.guard';
import {VersionGuard} from 'app/shared/guards/version/version.guard';

import {AuthGuard} from '@guards/auth';
import {ChangePasswordGuard} from '@guards/change-password';
import {UnAuthGuard} from '@guards/un-auth';
import {GeneralRoutes} from '@models/general';
import {UserResolver} from '@resolvers/user.resolver';

const routes: Routes = [
  {
    path: '',
    canActivate: [VersionGuard],
    children: [
      {
        path: GeneralRoutes.Auth,
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: '',
        loadChildren: () => import('./pages/wrapper/wrapper.module').then(m => m.WrapperModule),
      },
      {
        path: GeneralRoutes.PreferenceList,
        loadChildren: () => import('@features/charter/pages/user-preference-list-wrapper/user-preference-list-wrapper.module')
          .then(m => m.UserPreferenceListWrapperModule),
      },
      {
        path: '',
        canActivate: [OnlyDevGuard],
        children: [
          {
            path: 'icons',
            loadChildren: () => import('./shared/modules/seazone-icons/modules/seazone-icons-preview/seazone-icons-preview.module')
              .then(m => m.SeazoneIconsPreviewModule),
          },
          {
            path: 'controls',
            loadChildren: () => import('./shared/controls/controls-preview/controls-preview.module')
              .then(m => m.ControlsPreviewModule),
          },
        ],
      },
      {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
  })],
  exports: [RouterModule],
  providers: [AuthGuard, UnAuthGuard, ChangePasswordGuard, UserResolver],
})
export class AppRoutingModule {
}
