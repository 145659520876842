import {Inject, Injectable, isDevMode} from '@angular/core';

import {HotjarSettings} from '../interfaces/hotjar-settings';
import {HJ_FN} from '../tokens/hotjar-fn.token';
import {HOTJAR_SETTINGS_TOKEN} from '../tokens/hotjar-settings.token';
import {HjFn} from '../types/hj';

@Injectable({
  providedIn: 'root',
})
export class HotjarService {

  /**
   * Provide direct access to the `hj.*` static functions. If the desired function is not available on type definition,
   * you can cast to `any` as following.
   * (hjService.lib as any).myBrandNewStaticFn()
   */
  get lib(): HjFn {
    return this.hjFn;
  }

  constructor(
    @Inject(HJ_FN) private hjFn: HjFn,
    @Inject(HOTJAR_SETTINGS_TOKEN) private settings: HotjarSettings,
  ) {
  }

  /** Expose Hotjar Function calls */
  hj(...args: Array<any>) {
    try {
      this.hjFn(...args);
    } catch (err) {
      if (isDevMode()) {
        console.error(err.message);
      }
    }
  }

  /**
   * Fires an PageView event on Hotjar. Use this method to trigger an virtual url path. The same as
   * hj('vpv', path)
   */
  virtualPageView(path: string): void {
    this.hj('vpv', path);
  }

  /**
   * Fires an event on Hotjar. Use this method to trigger events on forms and start video recordings. Same as
   * hj('trigger', path)
   */
  trigger(path: string): void {
    this.hj('trigger', path);
  }

  /**
   * Allows you to tag recordings on Hotjar of all visitors passing through a page.
   *
   * You can create multiple tags by providing additional arguments
   *
   * @deprecated
   * hjService.tagRecording(['tag1', 'tag2', 'tag3', ...]);
   * hj('tagRecording', ['tag1', 'tag2', 'tag3', ...])
   */
  tagRecording(tagOrCollection: string[]): void;
  /**
   * Allows you to tag recordings on Hotjar of all visitors passing through a page.
   *
   * You can create multiple tags by providing aditional arguments
   * hjService.tagRecording('tag1', 'tag2', 'tag3', ...);
   * hj('tagRecording', ['tag1', 'tag2', 'tag3', ...])
   */
  tagRecording(tagOrCollection: string, ...tags: Array<string>): void;
  tagRecording(tagOrCollection: string | Array<string>, ...tags: Array<string>): void {
    if (!Array.isArray(tagOrCollection)) {
      tagOrCollection = [tagOrCollection];
    }
    this.hj('tagRecording', tagOrCollection.concat(...tags));
  }

  /**
   * This option is available in case you need to set up page change tracking manually
   * within your app's router.
   * hj('stateChange', path)
   */
  stateChange(path: string): void {
    this.hj('stateChange', path);
  }

  /**
   * Signals form submission success
   * hj('formSubmitSuccessful');
   */
  formSubmitSuccessful(): void {
    this.hj('formSubmitSuccessful');
  }

  /**
   * Signals form submission failure
   * hj('formSubmitFailed');
   */
  formSubmitFailed(): void {
    this.hj('formSubmitFailed');
  }

}
