import {PortalModule} from '@angular/cdk/portal';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CookieModule} from '@gorniv/ngx-universal';
import {LoaderComponent} from '@modules/custom-loader/components/loader/loader.component';
import {InfoModalModule} from '@modules/info-modal';
import {ModalModule} from '@modules/modal';
import {NotificationModule} from '@modules/notification';
import {PermissionsModule} from '@modules/permissions';
import {RolesModule} from '@modules/roles';
import {SeazoneIconsModule} from '@modules/seazone-icons';
import {ServiceWorkerUpdateModule} from '@modules/service-worker-update/service-worker-update.module';
import {GoogleAuthProvider, SocialAuthModule} from '@modules/social-auth';
import {TrialWarningModule} from '@modules/trial-warning/trial-warning.module';

import {CoreModule} from '@core/core.module';
import {HotjarModule} from '@core/modules/hotjar';
import {TransferHttpModule} from '@core/modules/transfer-http/transfer-http.module';
import {FileDownloaderModule} from '@directives/file-downloader';
import {environment} from '@environments/environment';
import {OnlyDevGuard} from '@guards/only-dev';
import {ApiInterceptor} from '@interceptors/api.interceptor';
import {CustomHeaderUpdateInterceptor} from '@interceptors/custom-header-update.interceptor';
import {ErrorInterceptor} from '@interceptors/error.interceptor';
import {FormDataInterceptor} from '@interceptors/form-data.interceptor';
import {HttpLanguageInterceptor} from '@interceptors/language.interceptor';
import {TokenInterceptor} from '@interceptors/token.interceptor';
import {UserService} from '@services/user/user.service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    LoaderComponent,
    BrowserModule,
    TransferHttpModule,
    HttpClientModule,
    AppRoutingModule,
    PortalModule,
    CoreModule,
    CookieModule.forRoot(),
    NotificationModule.forRoot(),
    PermissionsModule.forRoot(),
    SeazoneIconsModule.forRoot(),
    RolesModule.forRoot(),
    HotjarModule.forRoot(environment.hj),
    ModalModule,
    FileDownloaderModule,
    ServiceWorkerUpdateModule,
    InfoModalModule,
    SocialAuthModule.forRoot([
      // TODO: Temporary removed Facebook Auth
      // {
      //   type: 'facebook',
      //   provider: new FacebookAuthProvider({
      //     appId: environment.fbAppKey,
      //     configId: environment.fbConfigId,
      //   }),
      // },
      {
        type: 'google',
        provider: new GoogleAuthProvider(environment.gAppKey),
      },
    ], UserService),
    TrialWarningModule,
  ],
  providers: [
    OnlyDevGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FormDataInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHeaderUpdateInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
