import {Component, OnInit} from '@angular/core';

import {AppStateService} from '@core/modules';
import {User} from '@models/user';

import {CustomOverlayRef} from '../../../modal';

@Component({
  selector: 'app-service-worker-update-modal',
  templateUrl: './service-worker-update-modal.component.html',
  styleUrls: ['./service-worker-update-modal.component.scss'],
})
export class ServiceWorkerUpdateModalComponent implements OnInit {

  user: User | null;

  constructor(
    private readonly appStateService: AppStateService,
    private readonly overlayRef: CustomOverlayRef,
  ) {
  }

  ngOnInit(): void {
    this.user = this.appStateService.user;
  }

  onClose(): void {
    this.overlayRef.close(true);
  }
}
