import {CommonModule} from '@angular/common';
import {ModuleWithProviders, NgModule} from '@angular/core';

import {HotjarLazyInitializerService} from '@core/modules/hotjar/services/hotjar-lazy-initializer.service';

import {HOTJAR_INITIALIZER_PROVIDER} from './initializers/hotjar';
import {HOTJAR_ROUTER_INITIALIZER_PROVIDER} from './initializers/hotjar-router';
import {HotjarService} from './services/hotjar.service';
import {HOTJAR_SETTINGS_TOKEN} from './tokens/hotjar-settings.token';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    HotjarService,
    HotjarLazyInitializerService,
  ],
})
export class HotjarModule {

  constructor() {
  }

  static forChild(trackingCode: string | null, version: number = 6, uri?: string): ModuleWithProviders<HotjarModule> {
    return {
      ngModule: HotjarModule,
      providers: [
        {
          provide: HOTJAR_SETTINGS_TOKEN,
          useValue: {
            trackingCode,
            version,
            uri,
          },
        },
      ],
    };
  }

  static forRoot(
    trackingCode: string,
    debug = false,
    version: number = 6,
    uri?: string,
  ): ModuleWithProviders<HotjarModule> {
    return {
      ngModule: HotjarModule,
      providers: [
        {
          provide: HOTJAR_SETTINGS_TOKEN,
          useValue: {
            trackingCode,
            debug,
            version,
            uri,
          },
        },
        HOTJAR_INITIALIZER_PROVIDER,
        HOTJAR_ROUTER_INITIALIZER_PROVIDER,
      ],
    };
  }
}
