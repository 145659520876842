import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OverlayService} from '../../modal';
import {
  ServiceWorkerUpdateModalComponent,
} from '../components/service-worker-update-modal/service-worker-update-modal.component';

@Injectable()
export class ServiceWorkerUpdateModalService {

  constructor(
    private readonly overlayService: OverlayService,
  ) {
  }

  open(): Observable<boolean | null> {
    const ref = this.overlayService.open(
      ServiceWorkerUpdateModalComponent,
      null,
      {
        panelClass: ['seaz-modal', 'modal-small', 'seaz-rounded'],
        backdropClass: ['seaz-background', 'modal-background'],
        hasBackdrop: true,
        disposeOnNavigation: true,
      },
      {
        disableCloseBtn: true,
        preventBackdropClick: true,
      },
    );
    return ref?.afterClosed$.pipe(map(event => event.data)) || EMPTY;
  }
}
