import {isPlatformBrowser} from '@angular/common';
import {APP_BOOTSTRAP_LISTENER, ComponentRef, PLATFORM_ID, Provider} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map, startWith} from 'rxjs/operators';

import {HotjarSettings} from '@core/modules/hotjar/interfaces/hotjar-settings';
import {HotjarService} from '@core/modules/hotjar/services/hotjar.service';
import {HOTJAR_SETTINGS_TOKEN} from '@core/modules/hotjar/tokens/hotjar-settings.token';

export const HOTJAR_ROUTER_INITIALIZER_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: HotjarRouterInitializer,
  deps: [
    HotjarService,
    HOTJAR_SETTINGS_TOKEN,
    PLATFORM_ID,
  ],
};

export function HotjarRouterInitializer(
  hjService: HotjarService,
  settings: HotjarSettings,
  platformId: object,
) {
  return async (c: ComponentRef<any>) => {
    const isBrowser = isPlatformBrowser(platformId);
    if (!isBrowser) {
      return;
    }
    if (!settings.trackingCode) {
      return;
    }
    const router = c.injector.get(Router);
    const subs = router
      .events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        startWith(router.url),
      )
      .subscribe((url: string) => {
        hjService.stateChange(url);
      });
    c.onDestroy(() => subs.unsubscribe());
  };
}
