import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {createFormData} from '@idapgroup/js-object-utils';
import {Observable} from 'rxjs';

import {PlatformBrowserService} from '@core/modules';
import {booleanToConditionalStatus} from '@models/general/conditional-status.model';

@Injectable()
export class FormDataInterceptor implements HttpInterceptor {

  constructor(private readonly browserService: PlatformBrowserService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const type = request.method.toUpperCase();
    if (this.browserService.isBrowser && (type === 'PUT' || type === 'POST')) {
      const {body} = request;
      request = request.clone({
        body: createFormData(body, {booleanMapper: (val) => booleanToConditionalStatus(val).toString()}),
      });
    }

    return next.handle(request);
  }
}
