import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslocoModule} from '@ngneat/transloco';

import {
  ServiceWorkerUpdateModalComponent,
} from './components/service-worker-update-modal/service-worker-update-modal.component';
import {ServiceWorkerUpdateModalService} from './services/service-worker-update-modal.service';
import {ServiceWorkerUpdateService} from './services/service-worker-update.service';

import {ModalModule} from '../modal';

@NgModule({
  declarations: [
    ServiceWorkerUpdateModalComponent,
  ],
  imports: [
    CommonModule,
    ModalModule,
    TranslocoModule,
  ],
  providers: [
    ServiceWorkerUpdateService,
    ServiceWorkerUpdateModalService,
  ],
})
export class ServiceWorkerUpdateModule {
}
