import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoaderService} from '@modules/custom-loader';
import {PermissionsService} from '@modules/permissions';
import {ServiceWorkerUpdateService} from '@modules/service-worker-update/services/service-worker-update.service';
import {UserDownloadNotificationService} from '@modules/user-download-notification';
import {of} from 'rxjs';
import {debounceTime, filter, pairwise, switchMap, takeUntil} from 'rxjs/operators';

import {AppStateService} from '@core/modules';
import {FeatureToggleService} from '@core/modules/feature-toggle';
import {AppRouterService} from '@core/services';
import {DestroySubscription} from '@helpers/destroy-subscription';
import {scrollTop} from '@helpers/scroll-top';
import {AuthService} from '@services/auth/auth.service';
import {CrewCvCompletionService} from '@services/crew-cv-completion/crew-cv-completion.service';
import {GeolocationStoreService} from '@services/geolocation/geolocation-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends DestroySubscription implements OnInit, OnDestroy {

  constructor(
    private readonly appRouterService: AppRouterService,
    private readonly appStateService: AppStateService,
    private readonly permissionsService: PermissionsService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly featureToggleService: FeatureToggleService,
    private readonly loaderService: LoaderService,
    private readonly crewCvCompletionService: CrewCvCompletionService,
    private readonly userDownloadNotificationService: UserDownloadNotificationService,
    private readonly geolocationStoreService: GeolocationStoreService,
    private readonly serviceWorkerUpdateService: ServiceWorkerUpdateService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
    this.authService.init();
    this.permissionsService.init();
    this.crewCvCompletionService.init();
    this.userDownloadNotificationService.init();
    this.geolocationStoreService.init();
  }

  ngOnInit(): void {
    this.userPermissions();
    this.checkUser();
    this.onLogoutUser();
    this.scrollToTop();
    this.updateServiceWorker();
  }

  private scrollToTop(): void {
    this.appRouterService.navigationEnd$.pipe(
      pairwise(),
      switchMap(() => {
        const currentNavigation = this.router.getCurrentNavigation();
        const extras = (currentNavigation && currentNavigation.extras) || null;
        return of(extras);
      }),
      filter((extras) => !(extras?.state?.blockScroll)),
      takeUntil(this.destroyStream$),
    ).subscribe(() => {
      scrollTop();
    });
  }

  private checkUser(): void {
    this.authService.onCheckUser().pipe(
      takeUntil(this.destroyStream$),
    ).subscribe();
  }

  private onLogoutUser(): void {
    this.authService.logout().pipe(
      takeUntil(this.destroyStream$),
    ).subscribe();
  }

  private userPermissions(): void {
    this.permissionsService.boatsPermissions$.pipe(
      takeUntil(this.destroyStream$),
    ).subscribe();
  }

  private updateServiceWorker(): void {
    this.appRouterService.navigationStart$.pipe
    (debounceTime(100),
      takeUntil(this.destroyStream$),
    ).subscribe(() => this.serviceWorkerUpdateService.checkForUpdate());

    this.serviceWorkerUpdateService.updateServiceWorker().pipe(
      takeUntil(this.destroyStream$),
    ).subscribe(() => {
      this.document.location.reload();
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.loaderService.destroy();
    this.authService.destroy();
    this.appStateService.destroyState();
    this.permissionsService.destroy();
    this.featureToggleService.destroy();
    this.crewCvCompletionService.destroy();
    this.userDownloadNotificationService.destroy();
    this.geolocationStoreService.destroy();
  }

}
