import {inject, InjectionToken} from '@angular/core';

import {WINDOW} from '@core/tokens';

import {HjFn} from '../types/hj';

export function getHjFn(window: Window & { hj?: HjFn }): HjFn | null {
  return window?.hj || null;
}

/**
 * Provides an injection token to access Google Analytics Gtag Function
 */
export const HJ_FN = new InjectionToken<HjFn | null>('hj-fn', {
  providedIn: 'root',
  factory: () => getHjFn(inject(WINDOW)),
});
