import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {APP_INITIALIZER, PLATFORM_ID, Provider} from '@angular/core';

import {WINDOW} from '@core/tokens';

import {HotjarSettings} from '../interfaces/hotjar-settings';
import {HOTJAR_SETTINGS_TOKEN} from '../tokens/hotjar-settings.token';
import {HjFn} from '../types/hj';

export const HOTJAR_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: hotjarInitializer,
  deps: [
    HOTJAR_SETTINGS_TOKEN,
    PLATFORM_ID,
    DOCUMENT,
    WINDOW,
  ],
};

/**
 * Configuration Factory to create hotjar install script tag and attache on DOM at angular initialization.
 */
export function hotjarInitializer(
  settings: HotjarSettings,
  platformId: object,
  document: Document,
  window: Window & {
    hj: HjFn, _hjSettings: { hjid: string, hjsv: number }
  },
): () => Promise<void> {
  return async () => {
    const isBrowser = isPlatformBrowser(platformId);
    if (!isBrowser) {
      return;
    }
    if (!settings.trackingCode) {
      return;
    }

    if (!document || !window) {
      return;
    }

    const loadedScript = document.querySelector('script#hotjar');
    if (loadedScript) {
      return;
    }

    // tslint:disable-next-line:only-arrow-functions
    const hjFn = function() {
      (window.hj.q = window.hj.q || []).push(arguments);
    };
    Object.defineProperty(window, 'hj', {
      value: hjFn,
      configurable: true,
      writable: true,
    });

    Object.defineProperty(
      window,
      '_hjSettings',
      {
        value: {hjid: settings.trackingCode, hjsv: (settings.version || 6)},
        configurable: true,
        writable: true,
      },
    );

    const head = document.querySelector('head');
    if (!head) {
      return;
    }

    const script = document.createElement('script');
    const uri = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;

    script.async = true;
    script.src = (settings.uri || uri);
    script.setAttribute('id', 'hotjar');
    head.appendChild(script);
  };
}

/**
 * Configuration Factory to create hotjar install script tag and attache on DOM at angular initialization.
 */
export function hotjarDestroy(
  document: Document,
) {

  if (!document) {
    return;
  }
  const head = document.querySelector('head');
  if (!head) {
    return;
  }
  const script = document.querySelector('script#hotjar');
  if (!script) {
    return;
  }
  const parent = script.parentElement;
  if (!parent) {
    return;
  }
  parent.removeChild(script);
}
