<div *transloco="let t; read: 'sw'" class="info-modal info-modal-container">
  <div class="info-modal__header">
    <h2 class="seaz-title-h3">
      {{ t('title') }}<ng-container *ngIf="user">, {{ user.firstName }}</ng-container
      >!
    </h2>
  </div>

  <div class="info-modal__content">
    <div class="info-modal__text info-modal__text--custom-text" [innerHTML]="t('text')"></div>
  </div>

  <div class="info-modal__controls">
    <button class="seaz__btn_rounded" (click)="onClose()">{{ 'actions.ok' | transloco }}</button>
  </div>
</div>
