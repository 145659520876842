import {PortalModule} from '@angular/cdk/portal';
import {NgModule} from '@angular/core';
import {HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';

import {TranslocoBrowserModule} from '@core/modules';
import {FeatureToggleBrowserLoaderModule} from '@core/modules/feature-toggle';
import {environment} from '@environments/environment';

import {AppComponent} from './app.component';
import {AppModule} from './app.module';

import {REQUEST} from '../express.tokens';

export const getRequest = () => ({headers: {cookie: document.cookie}});

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    AppModule,
    PortalModule,
    BrowserAnimationsModule,
    HammerModule,
    TranslocoBrowserModule,
    FeatureToggleBrowserLoaderModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: REQUEST,
      useFactory: getRequest,
    },
    {provide: 'ORIGIN_URL', useValue: location.origin},
  ],
})
export class AppBrowserModule {
}
