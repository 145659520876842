import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AppStateService} from '../../core/modules/app-state';

@Injectable()
export class HttpLanguageInterceptor implements HttpInterceptor {

  constructor(private readonly appStateService: AppStateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = this.appStateService.language;
    const code = language || 'en';
    if (language) {
      request = request.clone({
        headers: request.headers.set('Accept-Language', code),
      });
    }
    return next.handle(request);
  }
}
