import {DOCUMENT} from '@angular/common';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';

import {WINDOW} from '@core/tokens';

import {hotjarDestroy, hotjarInitializer} from '../initializers/hotjar';
import {HotjarSettings} from '../interfaces/hotjar-settings';
import {HOTJAR_SETTINGS_TOKEN} from '../tokens/hotjar-settings.token';
import {HjFn} from '../types/hj';

@Injectable()
export class HotjarLazyInitializerService {

  constructor(
    @Inject(HOTJAR_SETTINGS_TOKEN) private readonly settings: HotjarSettings,
    @Inject(PLATFORM_ID) private readonly platformId: object,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window & { hj: HjFn, _hjSettings: { hjid: string, hjsv: number } },
  ) {
  }

  init(): void {
    hotjarInitializer(this.settings, this.platformId, this.document, this.window)().then();
  }

  destroy(): void {
    hotjarDestroy(this.document);
  }
}
