import {Inject, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {EMPTY, Observable} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';

import {UniversalStorageService} from '@core/modules';
import {WINDOW} from '@core/tokens';

import {ServiceWorkerUpdateModalService} from './service-worker-update-modal.service';

import config from '../../../../../../package.json';
import {LoaderService} from '../../custom-loader';

@Injectable()
export class ServiceWorkerUpdateService {

  private readonly versionKey = '_version';

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly serviceWorkerUpdateModalService: ServiceWorkerUpdateModalService,
    @Inject(WINDOW) private readonly window: Window,
    private readonly loaderService: LoaderService,
    @Inject(UniversalStorageService) private readonly universalStorageService: UniversalStorageService,
  ) {
  }

  updateServiceWorker(): Observable<boolean | null> {
    if (!this.swUpdate.isEnabled) {
      return EMPTY;
    }
    return this.swUpdate.versionUpdates.pipe(
      filter(evt => evt.type === 'VERSION_READY'),
      take(1),
      switchMap(_ => {
        this.universalStorageService.setItem(this.versionKey, config.version);
        this.loaderService.forceClose();
        return this.serviceWorkerUpdateModalService.open();
      }),
    );
  }

  checkForUpdate(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.swUpdate.checkForUpdate();
  }

}
